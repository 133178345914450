@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    font-family: "Poppins", sans-serif;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/**===== circle6 =====*/
#circle6 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 75px;
  width: 75px;
  margin: -25px 0 0 -25px;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 10px #ab1a58 solid;
  border-right: 10px #f9ad45 solid;
  border-bottom: 10px #5a3686 solid;
  border-left: 10px #51b8aa solid;
  border-radius: 50%;
  -webkit-animation: spin6 1s infinite linear;
  animation: spin6 1s infinite linear;
}

@-webkit-keyframes spin6 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin6 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.gradient_animation {
  background: linear-gradient(45deg, #5b3686, #2b59a7, #008fba, #48bba9);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
